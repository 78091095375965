// import React, { useRef, useState } from 'react'
// import { Modal } from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import { PhoneInput } from 'react-international-phone'
// import { useForm } from 'react-hook-form'
// import { PhoneNumberUtil } from 'google-libphonenumber'
// import { toast } from 'react-toastify'
// import { Toastify } from '../../Components/Toastify/Toastify'
// import { sendInTg } from '../../Constants'
// import { useNavigate } from 'react-router-dom' // <-- імпортуйте useNavigate
//
// import style from './PopupForm.module.css'
// import './modal.css'
// import { CloseBtn } from '../../Assets/Icons/icons'
//
// const PopUp = ({ show, handleClose, title }) => {
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors, touchedFields },
//   } = useForm({ mode: 'all' })
//
//   const [phone, setPhone] = useState('')
//   const [isBlurredPhone, setIsBlurredPhone] = useState(false)
//
//   const navigate = useNavigate() // <-- створюємо хук навігації
//
//   const isPhoneValid = (phone) => {
//     const phoneUtil = PhoneNumberUtil.getInstance()
//     try {
//       return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
//     } catch (error) {
//       return false
//     }
//   }
//
//   const isValid = isPhoneValid(phone)
//   const toastId = useRef(null)
//
//   const onSubmit = async ({ name, email }) => {
//     if (isValid) {
//       if (toastId.current) {
//         toast.dismiss(toastId.current)
//       }
//       toastId.current = toast(<Toastify type={'pending'} />, {
//         autoClose: false,
//         hideProgressBar: true,
//       })
//
//       let msg = `Заявка на консультацію:\nName - ${name}\nPhone - ${phone}\nEmail - ${email}`
//
//       let resp = await sendInTg(msg)
//       if (resp.ok) {
//         toast.update(toastId.current, {
//           render: <Toastify type={'success'} />,
//           type: 'success',
//           icon: false,
//           autoClose: 3000,
//           hideProgressBar: false,
//         })
//         handleClose()
//         navigate('/thankyou')
//       }
//       if (!resp.ok) {
//         toast.update(toastId.current, {
//           render: 'Упс, щось пішло не так...',
//           type: 'error',
//           autoClose: 7000,
//         })
//       }
//
//       reset()
//       setPhone('')
//       setIsBlurredPhone(false)
//     } else {
//       setIsBlurredPhone(true)
//     }
//   }
//
//   const redBord = {
//     boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)',
//   }
//   const greenBord = {
//     boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)',
//   }
//
//   return (
//     <Modal show={show} onHide={handleClose} centered>
//       {/*<Modal.Header closeButton></Modal.Header>*/}
//       <div className={style.popup_block}>
//         <Modal.Body>
//           <button className={style.popup_close_btn} onClick={handleClose}>
//             <img src={CloseBtn} alt="" />
//           </button>
//           <div className={style.wrap}>
//             <div>
//               <div className={style.box}>
//                 <div className={style.box_texts}>
//                   <h2 className={style.box_texts__title}>Зворотний зв’язок</h2>
//                 </div>
//                 <form
//                   onSubmit={handleSubmit(onSubmit)}
//                   className={style.box_form}
//                 >
//                   <div className={style.box_form__box}>
//                     <label htmlFor="Phone" className={style.form_box_label}>
//                       Імʼя:
//                     </label>
//                     <div
//                       className={style.box_form__box_wrap}
//                       style={
//                         errors?.name && touchedFields?.name
//                           ? redBord
//                           : !errors?.name && touchedFields?.name
//                           ? greenBord
//                           : null
//                       }
//                     >
//                       <input
//                         type="text"
//                         id="name"
//                         placeholder="Уведіть ваше імʼя"
//                         className={style.box_form__box_wrap__inp}
//                         {...register('name', {
//                           required: false,
//                           minLength: 2,
//                           maxLength: 30,
//                         })}
//                         autoComplete="off"
//                       />
//                     </div>
//                   </div>
//
//                   <div className={style.box_form__box}>
//                     <label htmlFor="Phone" className={style.form_box_label}>
//                       Телефон:
//                     </label>
//                     <div
//                       className={style.box_form__box_wrap}
//                       style={
//                         !isValid && isBlurredPhone
//                           ? redBord
//                           : isValid && isBlurredPhone
//                           ? greenBord
//                           : null
//                       }
//                     >
//                       <PhoneInput
//                         defaultCountry="ua"
//                         className={style.box_form__box_wrap__telInp}
//                         onChange={(e) => setPhone(e)}
//                         value={phone}
//                         onFocus={() => setIsBlurredPhone(true)}
//                       />
//                     </div>
//                   </div>
//                   <div className={style.box_form__box}>
//                     <label htmlFor="email" className={style.form_box_label}>
//                       Електронна пошта (не обов’язково):
//                     </label>
//                     <div
//                       className={style.box_form__box_wrap}
//                       style={
//                         errors?.email && touchedFields?.email
//                           ? redBord
//                           : !errors?.email && touchedFields?.email
//                           ? greenBord
//                           : null
//                       }
//                     >
//                       <input
//                         type="email"
//                         id={'email'}
//                         placeholder="Уведіть ваш e-mail"
//                         className={style.box_form__box_wrap__inp}
//                         {...register('email', {
//                           pattern: {
//                             value:
//                               /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
//                             message: 'Невірний формат email',
//                           },
//                           minLength: {
//                             value: 2,
//                             message: 'Email занадто короткий',
//                           },
//                           maxLength: {
//                             value: 30,
//                             message: 'Email занадто довгий',
//                           },
//                         })}
//                       />
//                     </div>
//                   </div>
//
//                   <div className={style.popup_block_btn}>
//                     <button className={style.box_form__btn}>Надіслати</button>
//                     <button
//                       className={style.popup_close_button}
//                       type="button"
//                       onClick={handleClose}
//                     >
//                       Відмінити
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//       </div>
//     </Modal>
//   )
// }
//
// export default PopUp

// import React, { useRef, useState } from 'react'
// import { Modal } from 'react-bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css'
// import { PhoneInput } from 'react-international-phone'
// import { useForm } from 'react-hook-form'
// import { PhoneNumberUtil } from 'google-libphonenumber'
// import { toast } from 'react-toastify'
// import { Toastify } from '../../Components/Toastify/Toastify'
// import { sendInTg, sendInCrm } from '../../Constants' // Додаємо sendInCrm
// import { useNavigate } from 'react-router-dom'
//
// import style from './PopupForm.module.css'
// import './modal.css'
// import { CloseBtn } from '../../Assets/Icons/icons'
//
// // Функція для отримання UTM міток з URL
// const getUTMParams = () => {
//   const urlParams = new URLSearchParams(window.location.search)
//   return {
//     utm_source: urlParams.get('utm_source') || '',
//     utm_medium: urlParams.get('utm_medium') || '',
//     utm_campaign: urlParams.get('utm_campaign') || '',
//     utm_term: urlParams.get('utm_term') || '',
//     utm_content: urlParams.get('utm_content') || '',
//   }
// }
//
// const PopUp = ({ show, handleClose, title }) => {
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { errors, touchedFields },
//   } = useForm({ mode: 'all' })
//
//   const [phone, setPhone] = useState('')
//   const [isBlurredPhone, setIsBlurredPhone] = useState(false)
//
//   const navigate = useNavigate()
//
//   const isPhoneValid = (phone) => {
//     const phoneUtil = PhoneNumberUtil.getInstance()
//     try {
//       return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
//     } catch (error) {
//       return false
//     }
//   }
//
//   const isValid = isPhoneValid(phone)
//   const toastId = useRef(null)
//
//   const onSubmit = async ({ name, email }) => {
//     if (isValid) {
//       if (toastId.current) {
//         toast.dismiss(toastId.current)
//       }
//       toastId.current = toast(<Toastify type={'pending'} />, {
//         autoClose: false,
//         hideProgressBar: true,
//       })
//
//       let msg = `Заявка на консультацію:\nName - ${name}\nPhone - ${phone}\nEmail - ${email}`
//
//       // Відправка в Telegram
//       let respTg = await sendInTg(msg)
//       if (respTg.ok) {
//         // Отримання UTM міток
//         const utmParams = getUTMParams()
//
//         // Відправка в CRM
//         let crmData = {
//           ...utmParams, // Додаємо UTM мітки
//           lead_name: name,
//           lead_phone: phone,
//           lead_email: email || '', // email може бути необов'язковим
//           lead_source: 'Сайт AIMA',
//         }
//
//         let respCrm = await sendInCrm(crmData)
//         console.log('CRM Response:', respCrm)
//         if (respCrm.ok) {
//           toast.update(toastId.current, {
//             render: <Toastify type={'success'} />,
//             type: 'success',
//             icon: false,
//             autoClose: 3000,
//             hideProgressBar: false,
//           })
//           handleClose() // Закриваємо попап
//           navigate('/thankyou') // Переадресація на сторінку "Дякую"
//         } else {
//           toast.update(toastId.current, {
//             render: 'Упс, щось пішло не так з відправкою у CRM...',
//             type: 'error',
//             autoClose: 7000,
//           })
//         }
//       } else {
//         toast.update(toastId.current, {
//           render: 'Упс, щось пішло не так з відправкою в Telegram...',
//           type: 'error',
//           autoClose: 7000,
//         })
//       }
//
//       reset()
//       setPhone('')
//       setIsBlurredPhone(false)
//     } else {
//       setIsBlurredPhone(true)
//     }
//   }
//
//   const redBord = {
//     boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)',
//   }
//   const greenBord = {
//     boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)',
//   }
//
//   return (
//     <Modal show={show} onHide={handleClose} centered>
//       <div className={style.popup_block}>
//         <Modal.Body>
//           <button className={style.popup_close_btn} onClick={handleClose}>
//             <img src={CloseBtn} alt="" />
//           </button>
//           <div className={style.wrap}>
//             <div>
//               <div className={style.box}>
//                 <div className={style.box_texts}>
//                   <h2 className={style.box_texts__title}>Зворотний зв’язок</h2>
//                 </div>
//                 <form
//                   onSubmit={handleSubmit(onSubmit)}
//                   className={style.box_form}
//                 >
//                   <div className={style.box_form__box}>
//                     <label htmlFor="Phone" className={style.form_box_label}>
//                       Імʼя:
//                     </label>
//                     <div
//                       className={style.box_form__box_wrap}
//                       style={
//                         errors?.name && touchedFields?.name
//                           ? redBord
//                           : !errors?.name && touchedFields?.name
//                           ? greenBord
//                           : null
//                       }
//                     >
//                       <input
//                         type="text"
//                         id="name"
//                         placeholder="Уведіть ваше імʼя"
//                         className={style.box_form__box_wrap__inp}
//                         {...register('name', {
//                           required: false,
//                           minLength: 2,
//                           maxLength: 30,
//                         })}
//                         autoComplete="off"
//                       />
//                     </div>
//                   </div>
//
//                   <div className={style.box_form__box}>
//                     <label htmlFor="Phone" className={style.form_box_label}>
//                       Телефон:
//                     </label>
//                     <div
//                       className={style.box_form__box_wrap}
//                       style={
//                         !isValid && isBlurredPhone
//                           ? redBord
//                           : isValid && isBlurredPhone
//                           ? greenBord
//                           : null
//                       }
//                     >
//                       <PhoneInput
//                         defaultCountry="ua"
//                         className={style.box_form__box_wrap__telInp}
//                         onChange={(e) => setPhone(e)}
//                         value={phone}
//                         onFocus={() => setIsBlurredPhone(true)}
//                       />
//                     </div>
//                   </div>
//                   <div className={style.box_form__box}>
//                     <label htmlFor="email" className={style.form_box_label}>
//                       Електронна пошта (не обов’язково):
//                     </label>
//                     <div
//                       className={style.box_form__box_wrap}
//                       style={
//                         errors?.email && touchedFields?.email
//                           ? redBord
//                           : !errors?.email && touchedFields?.email
//                           ? greenBord
//                           : null
//                       }
//                     >
//                       <input
//                         type="email"
//                         id={'email'}
//                         placeholder="Уведіть ваш e-mail"
//                         className={style.box_form__box_wrap__inp}
//                         {...register('email', {
//                           pattern: {
//                             value:
//                               /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
//                             message: 'Невірний формат email',
//                           },
//                           minLength: {
//                             value: 2,
//                             message: 'Email занадто короткий',
//                           },
//                           maxLength: {
//                             value: 30,
//                             message: 'Email занадто довгий',
//                           },
//                         })}
//                       />
//                     </div>
//                   </div>
//
//                   <div className={style.popup_block_btn}>
//                     <button className={style.box_form__btn}>Надіслати</button>
//                     <button
//                       className={style.popup_close_button}
//                       type="button"
//                       onClick={handleClose}
//                     >
//                       Відмінити
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </Modal.Body>
//       </div>
//     </Modal>
//   )
// }
//
// export default PopUp

import React, { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { PhoneInput } from 'react-international-phone'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { toast } from 'react-toastify'
import { Toastify } from '../../Components/Toastify/Toastify'
import { sendInTg, sendInCrm } from '../../Constants' // Додаємо sendInCrm
import { useNavigate } from 'react-router-dom'

import style from './PopupForm.module.css'
import './modal.css'
import { CloseBtn } from '../../Assets/Icons/icons'

// Функція для отримання UTM міток з URL
const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    utm_source: urlParams.get('utm_source') || '',
    utm_medium: urlParams.get('utm_medium') || '',
    utm_campaign: urlParams.get('utm_campaign') || '',
    utm_term: urlParams.get('utm_term') || '',
    utm_content: urlParams.get('utm_content') || '',
  }
}

const PopUp = ({ show, handleClose, title }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)

  const navigate = useNavigate()

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }

  const isValid = isPhoneValid(phone)
  const toastId = useRef(null)

  const onSubmit = async ({ name, email }) => {
    if (isValid) {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
      toastId.current = toast(<Toastify type={'pending'} />, {
        autoClose: false,
        hideProgressBar: true,
      })

      let msg = `Заявка на консультацію:\nName - ${name}\nPhone - ${phone}\nEmail - ${email}`
      const utmParams = getUTMParams()

      let crmData = {
        ...utmParams, // Додаємо UTM мітки
        lead_name: name,
        lead_phone: phone,
        lead_email: email || '', // email може бути необов'язковим
        lead_source: 'Сайт AIMA',
      }

      try {
        let respTg = await sendInTg(msg)
        let respCrm = await sendInCrm(crmData)
        console.log('CRM Response:', respCrm) // Логування відповіді CRM

        if (respCrm || respTg.ok) {
          console.log('asd')
          toast.update(toastId.current, {
            render: <Toastify type={'success'} />,
            type: 'success',
            icon: false,
            autoClose: 3000,
            hideProgressBar: false,
          })
        }
      } catch (e) {
        toast.update(toastId.current, {
          render: 'Упс, щось пішло не так з відправкою...',
          type: 'error',
          autoClose: 7000,
        })
      }

      reset()
      setPhone('')
      setIsBlurredPhone(false)
      handleClose() // Закриваємо попап
      navigate('/thankyou') // Переадресація на сторінку "Дякую"
    } else {
      setIsBlurredPhone(true)
    }
  }

  const redBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)',
  }
  const greenBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)',
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className={style.popup_block}>
        <Modal.Body>
          <button className={style.popup_close_btn} onClick={handleClose}>
            <img src={CloseBtn} alt="" />
          </button>
          <div className={style.wrap}>
            <div>
              <div className={style.box}>
                <div className={style.box_texts}>
                  <h2 className={style.box_texts__title}>Зворотний зв’язок</h2>
                </div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={style.box_form}
                >
                  <div className={style.box_form__box}>
                    <label htmlFor="Phone" className={style.form_box_label}>
                      Імʼя:
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        errors?.name && touchedFields?.name
                          ? redBord
                          : !errors?.name && touchedFields?.name
                          ? greenBord
                          : null
                      }
                    >
                      <input
                        type="text"
                        id="name"
                        placeholder="Уведіть ваше імʼя"
                        className={style.box_form__box_wrap__inp}
                        {...register('name', {
                          required: false,
                          minLength: 2,
                          maxLength: 30,
                        })}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className={style.box_form__box}>
                    <label htmlFor="Phone" className={style.form_box_label}>
                      Телефон:
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        !isValid && isBlurredPhone
                          ? redBord
                          : isValid && isBlurredPhone
                          ? greenBord
                          : null
                      }
                    >
                      <PhoneInput
                        defaultCountry="ua"
                        className={style.box_form__box_wrap__telInp}
                        onChange={(e) => setPhone(e)}
                        value={phone}
                        onFocus={() => setIsBlurredPhone(true)}
                      />
                    </div>
                  </div>
                  <div className={style.box_form__box}>
                    <label htmlFor="email" className={style.form_box_label}>
                      Електронна пошта (не обов’язково):
                    </label>
                    <div
                      className={style.box_form__box_wrap}
                      style={
                        errors?.email && touchedFields?.email
                          ? redBord
                          : !errors?.email && touchedFields?.email
                          ? greenBord
                          : null
                      }
                    >
                      <input
                        type="email"
                        id={'email'}
                        placeholder="Уведіть ваш e-mail"
                        className={style.box_form__box_wrap__inp}
                        {...register('email', {
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Невірний формат email',
                          },
                          minLength: {
                            value: 2,
                            message: 'Email занадто короткий',
                          },
                          maxLength: {
                            value: 30,
                            message: 'Email занадто довгий',
                          },
                        })}
                      />
                    </div>
                  </div>

                  <div className={style.popup_block_btn}>
                    <button className={style.box_form__btn}>Надіслати</button>
                    <button
                      className={style.popup_close_button}
                      type="button"
                      onClick={handleClose}
                    >
                      Відмінити
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default PopUp
