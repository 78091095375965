import React from 'react'
import './footer.css'
import { HeaderLogoMobile } from '../../Assets/Images/MainBlock'
// import { Link } from 'react-router-dom'
import { Link } from 'react-scroll'
const Footer = () => {
  return (
    <div className="footer_block">
      <div className="customContainer footer_content_block">
        <div className="footer_top_block">
          <div className="footer_top_left">
            <Link
              to="offer"
              smooth={true}
              duration={500}
              className="footer_nav_link_text"
            >
              <p className="footer_nav_link_text">Що ми пропонуємо?</p>
            </Link>
            <Link
              to="howworks"
              smooth={true}
              duration={500}
              className="footer_nav_link_text"
            >
              <p className="footer_nav_link_text">Як це працює?</p>
            </Link>
            <Link
              to="why"
              smooth={true}
              duration={500}
              className="footer_nav_link_text"
            >
              <p className="footer_nav_link_text">Чому ми?</p>
            </Link>
            <Link
              to="advantage"
              smooth={true}
              duration={500}
              className="footer_nav_link_text"
            >
              <p className="footer_nav_link_text">Наші переваги</p>
            </Link>
            <Link
              to="faq"
              smooth={true}
              duration={500}
              className="footer_nav_link_text"
            >
              <p className="footer_nav_link_text">Часті запитання</p>
            </Link>
          </div>
          <div className="footer_top_right">
            <p className="footer_contact_text">
              <a
                className="footer_contact_text"
                href="https://maps.app.goo.gl/93UurF7DaFcxV66d9"
                target="_blank"
                rel="noopener noreferrer"
              >
                м. Львів, вул. Героїв УПА, 73Ж
              </a>
            </p>
            <p className="footer_contact_text">
              <a
                className="footer_contact_text"
                target="_blank"
                rel="noopener noreferrer"
                href="tel:+380687003020"
              >
                +38 (068) 700-30-20
              </a>
            </p>
            <p className="footer_contact_text">
              <a
                className="footer_contact_text"
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:info@aima.in.ua"
              >
                info@aima.in.ua
              </a>
            </p>
          </div>
        </div>
        <div className="footer_bottom_block">
          <div>
            <img className="footer_logo" src={HeaderLogoMobile} alt="" />
          </div>
          <div>
            <p className="footer_bottom_copiryght">
              © 2024 All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
