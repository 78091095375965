import React, { useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { toast } from 'react-toastify'
import { Toastify } from '../../Components/Toastify/Toastify'
import { sendInTg } from '../../Constants'
import 'react-international-phone/style.css'
import { PhoneInput } from 'react-international-phone'
import styles from './Form.module.css'

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false) // State to track submission status

  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }

  const isValid = isPhoneValid(phone)
  const toastId = useRef(null)

  const onSubmit = async ({ name }) => {
    if (isValid) {
      if (toastId.current) {
        toast.dismiss(toastId.current)
      }
      toastId.current = toast(<Toastify type={'pending'} />, {
        autoClose: false,
        hideProgressBar: true,
      })

      let msg = `Заявка на консультацію:\nName - ${name}\nPhone - ${phone}`

      let resp = await sendInTg(msg)
      if (resp.ok) {
        toast.update(toastId.current, {
          render: <Toastify type={'success'} />,
          type: 'success',
          icon: false,
          autoClose: 3000,
          hideProgressBar: false,
        })

        // Set submission state and clear form after 5 seconds
        setIsSubmitted(true)
        setTimeout(() => {
          setIsSubmitted(false) // Reset to show the form again
          reset()
          setPhone('')
          setIsBlurredPhone(false)
        }, 5000)
      } else {
        toast.update(toastId.current, {
          render: 'Упс, щось пішло не так...',
          type: 'error',
          autoClose: 7000,
        })
      }
    } else {
      setIsBlurredPhone(true)
    }
  }

  const redBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(255,0,0,.2)',
  }

  const greenBord = {
    boxShadow: 'inset 0px 0px 5px 5px rgba(100,255,100,.2)',
  }

  return (
    <section className={styles.contactSection} id={'form'}>
      <div className={'customContainer'}>
        {isSubmitted ? ( // Conditional rendering
          <h2 className={styles.title}>
            Дякуємо, ваша заявка успішно відправлена
          </h2>
        ) : (
          <>
            <h2 className={styles.title}>
              Дізнайтеся, як ми можемо допомогти вашому бізнесу зростати!
            </h2>
            <p className={styles.subtitle}>
              Заповніть форму для безкоштовної консультації!
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
              <div className={styles.formGroup}>
                <label htmlFor="name" className={styles.label}>
                  Ім'я:
                </label>
                <input
                  style={
                    errors?.name && touchedFields?.name
                      ? redBord
                      : !errors?.name && touchedFields?.name
                      ? greenBord
                      : null
                  }
                  type="text"
                  id="name"
                  placeholder="Уведіть ваше ім'я"
                  className={styles.input}
                  {...register('name', {
                    required: true,
                    minLength: 2,
                    maxLength: 30,
                  })}
                />
              </div>

              <div className={styles.formGroup}>
                <label htmlFor="Phone" className={styles.label}>
                  Телефон:
                </label>
                <PhoneInput
                  style={
                    !isValid && isBlurredPhone
                      ? redBord
                      : isValid && isBlurredPhone
                      ? greenBord
                      : null
                  }
                  defaultCountry="ua"
                  className={styles.input}
                  onChange={(e) => setPhone(e)}
                  value={phone}
                  onFocus={() => setIsBlurredPhone(true)}
                />
              </div>

              <button type="submit" className={styles.button}>
                Надіслати
              </button>
            </form>
          </>
        )}
      </div>
    </section>
  )
}

export default ContactForm
