import React from 'react'
import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import style from './ThankPopUp.module.css'
import './modal.css'
import { CloseBtn } from '../../Assets/Icons/icons'
import LottieAnimation from './LottieAnimation'
import { Link } from 'react-router-dom'

const ThankPopUp = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className={style.popup_block}>
        <Modal.Body>
          <Link to={'/'}>
            <button className={style.popup_close_btn} onClick={handleClose}>
              <img src={CloseBtn} alt="Close" />
            </button>
          </Link>
          <div className={style.wrap}>
            <div>
              <div className={style.box}>
                <div className={style.animation_block}>
                  <LottieAnimation />
                </div>
                <div>
                  <h3 className={style.thank_title}>🎉 Заявку надіслано</h3>
                  <p className={style.thank_text}>
                    Дякуємо за вашу заявку! Наш менеджер зв'яжеться з вами
                    найближчим часом для подальшого обговорення та надання
                    необхідної інформації.
                  </p>
                </div>
                <Link
                  className={style.link_decoration}
                  to={'/'}
                  onClick={handleClose}
                >
                  <button className={style.box_form__btn}>На головну</button>
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  )
}

export default ThankPopUp
