import React, { useEffect, useState } from 'react'
import styles from './WebHeader.module.css'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { HeaderLogo } from '../../../Assets/Images/MainBlock'
import PopUp from '../../../Components/PopUp/PopUp'

const WebHeader = () => {
  const [show, setShow] = useState(null)

  const [showPopup, setShowPopup] = useState(false)

  const handleShow = () => setShowPopup(true)
  const handleClose = () => setShowPopup(false)

  const controlNavBar = _.throttle(() => {
    const currentScrollY = window.scrollY
    if (lastScrollY < currentScrollY) {
      setShow('true')
    } else {
      setShow('false')
    }
    lastScrollY = currentScrollY
  }, 200)

  let lastScrollY = window.scrollY

  useEffect(() => {
    const handleScroll = () => {
      controlNavBar()
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <header
      className={`${styles.wrap} ${
        show === 'false'
          ? styles.wrapShow
          : show === 'true'
          ? styles.wrapHidden
          : null
      }`}
    >
      <div className={`customContainer ${styles.container}`}>
        <Link to={'/'}>
          <img src={HeaderLogo} alt="logo" className={styles.container_logo} />
        </Link>

        <div className={styles.header_nav_block}>
          <ul className={styles.container_linksWrap}>
            {/*<li className={styles.container_linksWrap__link}>*/}
            {/*  <NavLink*/}
            {/*    className={({ isActive }) =>*/}
            {/*      isActive*/}
            {/*        ? `${styles.container_linksWrap__link} ${styles.activeLink}`*/}
            {/*        : styles.container_linksWrap__link*/}
            {/*    }*/}
            {/*    to="/siding"*/}
            {/*  >*/}
            {/*    Siding*/}
            {/*  </NavLink>*/}
            {/*</li>*/}
          </ul>

          <button onClick={handleShow} className={styles.container_btn}>
            Спробувати
          </button>
        </div>
        <PopUp show={showPopup} handleClose={handleClose} title="Popup Title">
          <p>This is the content of the popup.</p>
        </PopUp>
      </div>
    </header>
  )
}

export default WebHeader
