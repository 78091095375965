import React from 'react'
import { Player } from '@lottiefiles/react-lottie-player'
import animationData from './Animation.json'

const LottieAnimation = () => {
  return (
    <div>
      <Player
        autoplay
        keepLastFrame
        src={animationData}
        style={{ height: '160px', width: '178px' }} // Налаштування розмірів
      />
    </div>
  )
}

export default LottieAnimation
