import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styles from './MobHeader.module.css'
// import { Link } from 'react-router-dom'
import { Link } from 'react-scroll'

// import { HeaderBtn } from '../../../Assets/Images/LandingPage/Header'
// import { useDisclosure } from '@nextui-org/react'
import { HeaderLogoMobile } from '../../../Assets/Images/MainBlock'
// import { PopUp } from '../../../Pages/LandingPage/PopUp'

const MobHeader = () => {
  const [show, setShow] = useState(false)
  // const modalState = useDisclosure()
  // const { onOpen } = modalState

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div>
      <header className={styles.wrap}>
        <div className={`customContainer ${styles.wrap_container}`}>
          <Link to={'/'}>
            <img
              src={HeaderLogoMobile}
              alt="logo"
              className={styles.wrap_container_logo}
            />
          </Link>
          <div className={styles.menu_block}>
            <p onClick={handleShow} className={styles.menu_block_text}>
              Меню
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={handleShow}
            >
              <path
                d="M3 12H15M3 6H21M3 18H21"
                stroke="#344054"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            style={{
              top: '60px',
              width: '90%',
              transform: 'translateX(5%)',
            }}
          >
            <div
              onClick={handleClose}
              className={styles.wrap_container_closeBtn}
            >
              <p className={styles.wrap_container_closeText}>Меню</p>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                onClick={handleClose}
              >
                <path
                  d="M15 5L5 15M5 5L15 15"
                  stroke="#98A2B3"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <ul className={styles.wrap_container_modalWrap}>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="offer"
                  offset={-30}
                  onClick={handleClose}
                >
                  Що ми пропонуємо?
                </Link>
              </li>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="howworks"
                  offset={-30}
                  onClick={handleClose}
                >
                  Як це працює?
                </Link>
              </li>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="why"
                  onClick={handleClose}
                >
                  Чому ми?
                </Link>
              </li>
              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="advantage"
                  onClick={handleClose}
                >
                  Наші переваги
                </Link>
              </li>

              <li className={styles.wrap_container_modalWrap__link}>
                <Link
                  className={styles.wrap_container_modalWrap__link}
                  to="faq"
                  onClick={handleClose}
                >
                  Часті запитання
                </Link>
              </li>
              <div className={styles.mob_nav_contact_block}>
                <p className={styles.mob_nav_contact}>+38 (068) 700-30-20</p>
                <p className={styles.mob_nav_contact}>info@domain.com</p>
                <p className={styles.mob_nav_contact}>
                  м. Львів, вул. Героїв УПА, 73Ж
                </p>
              </div>
            </ul>
          </Modal>
        </div>
      </header>
      {/*<PopUp modalState={modalState} title={'Get in touch'} />*/}
    </div>
  )
}

export default MobHeader
