import React from 'react'
import MainBlock from './MainBlock/MainBlock'
import Website from './Website/Website'
import ERP from './ERP/ERP'
import Integrations from './Integrations/Integrations'
import Faq from './Faq/Faq'
import CRM from './CRM/CRM'
import Offer from './Offer/Offer'
import Advantages from './Advantages/Advantages'
import Why from './WhyUs/Why'
import HowItWorks from './HowItWorks/HowItWorks'
import Form from '../../Modules/Form/Form'

const MainPage = () => {
  return (
    <div>
      <MainBlock />
      <Offer />
      <HowItWorks />
      <Website />
      <CRM />
      <ERP />
      <Why />
      <Integrations />
      <Advantages />
      <Form />
      <Faq />
    </div>
  )
}

export default MainPage
