import './App.css'
import MainPage from './Pages/MainPage/MainPage'
import Header from './Modules/Header/Header'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Footer from './Modules/Footer/Footer'
import ThankPage from './Pages/ThankPage/ThankPage'
// import { ToastContainer } from 'react-toastify'

function App() {
  return (
    <div className="App">
      <Router>
        {/*<ToastContainer />*/}
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/thankyou" element={<ThankPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  )
}

export default App
