import React from 'react'
import styles from './Advantage.module.css'
import { image1, image2 } from '../../../Assets/Images/Advantages/Index'

const Advantages = () => {
  return (
    <div className={styles.container} id={'advantage'}>
      <h2 className={styles.desc}>Наші переваги</h2>
      <h2 className={styles.title}>
        Підвищуй прибутковість свого бізнесу завдяки aima
      </h2>
      <div className={styles.content}>
        {/* Left side */}
        <div className={styles.card}>
          <div className={styles.beforeTitle}>До aima</div>
          <img src={image1} alt="Excel Table" className={styles.image} />
          <p className={styles.infoText}>
            Обробка замовлень в Excel-таблиці
            <br />
            <span className={styles.infoText_sub}>Кількість продажів: </span>
            <span className={styles.decrease}>↓ 6%</span>
            <br />
            <span className={styles.infoText_mod}>
              (Втрачаєте клієнтів через довгі процеси та недостатній контроль)
            </span>
          </p>
        </div>

        {/* Right side */}
        <div className={styles.card}>
          <div className={styles.afterTitle}>Разом з aima</div>
          <img src={image2} alt="CRM System" className={styles.image} />
          <div className={styles.infoText}>
            Обробка замовлення в CRM-системі
            <br />
            <span className={styles.infoText_sub}>Кількість продажів: </span>
            <span className={styles.increase}>↑ 17%</span>
            <br />
            <span className={styles.infoText_mod}>
              (Оптимізація процесу обробки замовлень в інтегрованій CRM-системі)
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Advantages
