import React from 'react'
import styles from './Offer.module.css'
import { OfferImg } from '../../../Assets/Images/Offer/Index'

const Offer = () => {
  return (
    <section className={styles.promoBlock} id={'offer'}>
      <div className={`${'customContainer'} ${styles.content}`}>
        <div className={styles.textBlock}>
          <div className={styles.icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M10.4999 20.4167H4.08325M7.58325 14H2.33325M10.4999 7.58333H4.66659M19.8333 3.5L12.1374 14.2742C11.7968 14.7511 11.6265 14.9895 11.6338 15.1883C11.6403 15.3614 11.7233 15.5226 11.8604 15.6285C12.0178 15.75 12.3109 15.75 12.8969 15.75H18.6666L17.4999 24.5L25.1958 13.7258C25.5364 13.2489 25.7067 13.0105 25.6993 12.8117C25.6929 12.6386 25.6099 12.4774 25.4728 12.3715C25.3153 12.25 25.0223 12.25 24.4363 12.25H18.6666L19.8333 3.5Z"
                stroke="#1586EF"
              />
            </svg>
          </div>
          <h2 className={styles.title}>Що ми пропонуємо</h2>
          <p className={styles.description}>
            Автоматизований <span>інтернет-магазин</span> з впровадженою
            <span> CRM</span> та <span>ERP-системами</span>, які дозволяють
            ефективно керувати бізнесом, поєднуючи продажі ваших товарів,
            обробку замовлень та управління внутрішніми процесами.
          </p>
          <a href="#howworks" className={styles.button}>
            Дізнатись більше
          </a>
        </div>
        <img src={OfferImg} alt="Laptop view" className={styles.laptopImage} />
      </div>
    </section>
  )
}

export default Offer
