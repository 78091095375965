import React, { useState, useEffect } from 'react'
import Offer from '../MainPage/Offer/Offer'
import MainBlock from '../MainPage/MainBlock/MainBlock'
import ThankPopUp from '../../Components/ThankPopUp/ThankPopUp'

const ThankPage = () => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    // Вмикаємо попап одразу після завантаження сторінки
    setShowModal(true)
  }, [])

  const handleClose = () => setShowModal(false)

  return (
    <div>
      <MainBlock />
      <Offer />
      <ThankPopUp show={showModal} handleClose={handleClose} />
    </div>
  )
}

export default ThankPage
