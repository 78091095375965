import React from 'react'
import TextItem from '../../../Components/TextItem/TextItem'
import styles from './CRM.module.css'
import ReviewCard from '../../../Components/ReviewCard/ReviewCard'
import {
  CRMIcon,
  CRMIcon1,
  CRMIcon2,
  CRMIcon3,
  CRMImg,
  CRMImgIcon,
  Krupa,
} from '../../../Assets/Icons/CRM/Index'

const CRM = () => {
  const content = {
    subtitle: 'CRM-система',
    subtitleImage: CRMIcon, // Імпортоване зображення іконки
    title: 'Обробляй всі замовлення в одному місці через зручну CRM-систему',
    description:
      'Легко керуй усіма замовленнями в одному місці за допомогою інтегрованої CRM-системи.',
    benefits: [
      'Швидка обробка замовлень',
      'Розрахунок доходу і рентабельності',
      'Контроль статусу замовлень',
    ],
    servicesText: 'Aima синхронізується з такими сервісами:',
    services: [
      { name: 'monobank', logo: CRMIcon1 },
      { name: 'Приват24', logo: CRMIcon2 },
      { name: 'WAYFORPAY', logo: CRMIcon3 },
    ],
    buttonText: '', // У цьому випадку немає кнопки
  }

  const review = {
    image: Krupa,
    name: 'Крупа Богдана',
    position:
      'Співвласниця інтернет-магазину військової атрибутики ”Символіка”',
    stars: 5,
    text: 'Обробляти замовлення стало надзвичайно зручно! Мені більше не потрібно використовувати купу різних програм – маю свою CRM та інтернет-магазин в одному місці. Усе працює чітко і просто, що економить час і дозволяє зосередитися на бізнесі.Дуже задоволена таким рішенням!',
  }

  return (
    <div className={styles.hidden}>
      <div className={`${styles.container} ${'customContainer'}`}>
        <TextItem
          content={content}
          customClasses={{
            subtitle: styles.subtitle,
            subtitleContainer: styles.subtitleContainer,
            title: styles.title,
            description: styles.description,
            benefitItem: styles.benefitItem,
            servicesText: styles.servicesText,
          }}
        />
        <img src={CRMImg} alt="imageIcon" className={styles.image} />
      </div>
      <div className={`${styles.wrap} ${'customContainer'}`}>
        <ReviewCard
          review={review}
          customClasses={{
            name: styles.name,
            reviewCard: styles.reviewCard,
            text: styles.text,
            rating: styles.rating,
          }}
        />
        <img src={CRMImgIcon} alt="imageIcon" className={styles.imageIcon} />
      </div>
    </div>
  )
}

export default CRM
