import React from 'react'
import styles from './ReviewCard.module.css' // Підключаємо CSS модуль

const ReviewCard = ({ review, customClasses = {} }) => {
  return (
    <div className={`${styles.reviewCard} ${customClasses.reviewCard || ''}`}>
      <span className={`${styles.top} ${customClasses.top || ''}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="24"
          viewBox="0 0 35 24"
          fill="none"
        >
          <path
            opacity="0.5"
            d="M7.87149 9.6V10.1647C9.74565 10.3529 11.3855 11.1059 12.7912 12.4235C14.1968 13.6471 14.8996 15.2 14.8996 17.0824C14.8996 18.8706 14.2436 20.4706 12.9317 21.8824C11.6198 23.2941 9.83936 24 7.59036 24C5.43507 24 3.60776 23.0118 2.10843 21.0353C0.702811 19.0588 0 16.6118 0 13.6941C0 10.7765 1.26506 7.76471 3.79518 4.65883C6.41901 1.55294 9.04284 0 11.6667 0C12.51 0 12.9317 0.141177 12.9317 0.423532C12.9317 0.705887 12.7443 0.847065 12.3695 0.847065C11.9946 0.847065 11.2918 1.55295 10.261 2.96471C8.66801 5.0353 7.87149 7.24706 7.87149 9.6ZM27.9719 9.6V10.1647C29.8461 10.3529 31.4859 11.1059 32.8916 12.4235C34.2972 13.6471 35 15.2 35 17.0824C35 18.8706 34.344 20.4706 33.0321 21.8824C31.8139 23.2941 30.0803 24 27.8313 24C25.676 24 23.8487 23.0118 22.3494 21.0353C20.8501 19.0588 20.1004 16.6118 20.1004 13.6941C20.1004 10.7765 21.4123 7.76471 24.0361 4.65883C26.66 1.55294 29.2838 0 31.9076 0C32.6573 0 33.0321 0.141177 33.0321 0.423532C33.0321 0.705887 32.9384 0.847065 32.751 0.847065C32.1888 0.847065 31.3922 1.55295 30.3614 2.96471C28.7684 5.12941 27.9719 7.34118 27.9719 9.6Z"
            fill="#D0D5DD"
          />
        </svg>
      </span>
      <span className={`${styles.bottom} ${customClasses.bottom || ''}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="24"
          viewBox="0 0 35 24"
          fill="none"
        >
          <path
            opacity="0.5"
            d="M27.1285 14.4V13.8353C25.2544 13.6471 23.6145 12.9412 22.2088 11.7176C20.8032 10.4 20.1004 8.84706 20.1004 7.05882C20.1004 5.17647 20.7564 3.52942 22.0683 2.11765C23.3802 0.705884 25.1138 0 27.2691 0C29.5181 0 31.3454 0.988236 32.751 2.96471C34.2503 4.94118 35 7.38824 35 10.3059C35 13.2235 33.6881 16.2353 31.0643 19.3412C28.5341 22.4471 25.9572 24 23.3333 24C22.49 24 22.0683 23.8588 22.0683 23.5765C22.0683 23.2941 22.2557 23.1529 22.6305 23.1529C23.0054 23.1529 23.7082 22.4471 24.739 21.0353C26.332 18.9647 27.1285 16.7529 27.1285 14.4ZM7.02811 14.4V13.8353C5.15395 13.6471 3.51406 12.9412 2.10843 11.7176C0.702811 10.4 0 8.84706 0 7.05882C0 5.17647 0.609103 3.52942 1.82731 2.11765C3.13922 0.705884 4.87283 0 7.02811 0C9.27711 0 11.1513 0.988236 12.6506 2.96471C14.1499 4.94118 14.8996 7.38824 14.8996 10.3059C14.8996 13.2235 13.5877 16.2353 10.9639 19.3412C8.34003 22.4471 5.7162 24 3.09237 24C2.3427 24 1.96787 23.8588 1.96787 23.5765C1.96787 23.2941 2.06158 23.1529 2.249 23.1529C2.81124 23.1529 3.60776 22.4471 4.63855 21.0353C6.23159 18.8706 7.02811 16.6588 7.02811 14.4Z"
            fill="#D0D5DD"
          />
        </svg>
      </span>
      <div className={`${styles.header} ${customClasses.header || ''}`}>
        <img
          src={review.image}
          alt={review.name}
          className={`${styles.image} ${customClasses.image || ''}`}
        />
        <div className={`${styles.info} ${customClasses.info || ''}`}>
          <h3 className={`${styles.name} ${customClasses.name || ''}`}>
            {review.name}
          </h3>
          <p className={`${styles.position} ${customClasses.position || ''}`}>
            {review.position}
          </p>
        </div>
      </div>

      <div className={`${styles.rating} ${customClasses.rating || ''}`}>
        {Array.from({ length: review.stars }, (_, index) => (
          <span
            key={index}
            className={`${styles.star} ${customClasses.star || ''}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <g clip-path="url(#clip0_522_1215)">
                <path
                  d="M6.67684 1.12683C6.7964 0.839376 7.2036 0.839376 7.32316 1.12683L8.76943 4.60406C8.81983 4.72525 8.93379 4.80805 9.06462 4.81853L12.8186 5.11949C13.1289 5.14437 13.2093 5.49619 12.9474 5.7342L10.2553 8.42657C10.1269 8.55682 10.1044 8.75588 10.1677 8.91595L11.1187 12.2611C11.1609 12.3722 11.0531 12.4885 10.9439 12.4286L7.77949 10.6953C7.61479 10.5865 7.38521 10.5865 7.22051 10.6953L4.05613 12.4286C3.94691 12.4885 3.83912 12.3722 3.88127 12.2611L4.8323 8.91595C4.89561 8.75588 4.87306 8.55682 4.7447 8.42657L2.05257 5.7342C1.79071 5.49619 1.87112 5.14437 2.18139 5.11949L5.93538 4.81853C6.06621 4.80805 6.18017 4.72525 6.23057 4.60406L6.67684 1.12683Z"
                  fill="#F9D346"
                />
              </g>
              <defs>
                <clipPath id="clip0_522_1215">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
        ))}
      </div>
      <p className={`${styles.text} ${customClasses.text || ''}`}>
        {review.text}
      </p>
    </div>
  )
}

export default ReviewCard
